import UserInfomation from './components/UserInfomation';
import UserPost from './components/UserPost';

const Mypage = () => {
    return (
        <>
            <UserInfomation />
            <UserPost />
        </>
    );
};

export default Mypage;
