import UserInfoModify from '../components/UserInfoModify';

const UserModify = () => {
    return (
        <>
            <UserInfoModify />
        </>
    );
};

export default UserModify;
