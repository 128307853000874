import ApplicationModify from '../components/application/ApplicationModify';

const HackathonsApplicationPage = () => {
    return (
        <>
            <ApplicationModify />
        </>
    );
};

export default HackathonsApplicationPage;
