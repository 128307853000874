//import '../components/LoadScript';

import { Login } from './components/Login';

const LoginPage = () => {
    return (
        <>
            <Login />
        </>
    );
};

export default LoginPage;
