import About from './components/About';
import GlobalStyles from '../../styles/GlobalStyle';
//import '../components/LoadScript';

const AboutPage = () => {
    return (
        <>
            <GlobalStyles />
            <About />
        </>
    );
};

export default AboutPage;
