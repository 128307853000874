import React from 'react';
import Header from './UnivHeader';
import { Univcontent } from './Univcontent';

function UnivRecruit() {
    return (
        <>
            <Header />
            <Univcontent />
        </>
    );
}

export default UnivRecruit;
